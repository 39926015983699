import React, { useState, useEffect, useRef } from "react";
import KoeAddressDetails from "./KoeAddressDetails.js";

import CallForBank from "./CallForBank.js";
import ".././main.css";
import BuyerAddressDetails from "./BuyerAddressDetails.js";
import FirstFooter from "./FirstFooter.js";
import BillDated from "./BillDated.js";
import SecondFooter from "./SecondFooter.js";
import { useReactToPrint } from "react-to-print";
import CallForLogo from "./CallForLogo.js";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import LetterHead from "./LetterHead.jpeg"
import "./LetterHeadInvoice.css"


const queryString = require("query-string");

const InvoicePage = React.forwardRef((props, ref) => {

  var dateFormat = require('dateformat');

  const [invoiceData, Set_invoiceData] = useState([]);
  const [BankData, Set_BankData] = useState([]);
  const [invoiceCurrency, Set_invoiceCurrency] = useState([]);
  const [KoeName, set_KoeName] = useState("");
  const [KoeAddress, set_KoeAddress] = useState("");
  const [invoiceNo, Set_invoiceNo] = useState("");
  const [Currency, set_Currency] = useState("");
  const [ClientCountry, set_ClientCountry] = useState("");
  const [BuyerName, set_BuyerName] = useState("");
  const [BuyerAddress, set_BuyerAddress] = useState("");
  const queryURL = queryString.parseUrl(window.location.href);
  const [invid, setinvid] = useState(queryURL.query.invid);
  const [isSez, set_isSez] = useState(0);
  const [ICGST, set_ICGST] = useState(0);
  const [SCGST, set_SCGST] = useState(0);
  const [GCGST, set_GCGST] = useState(0);
  const [VAT, set_VAT] = useState(0);
  const [WHT, set_WHT] = useState(0);


  const [ICGST1, set_ICGST1] = useState(0);
  const [SCGST1, set_SCGST1] = useState(0);
  const [GCGST1, set_GCGST1] = useState(0);
  const [VAT1, set_VAT1] = useState(0);
  const [WHT1, set_WHT1] = useState(0);

  const [RT_Line, set_RT_Line] = useState("")
  const [LUTNo, set_LUTNo] = useState("")
  const [WHTRate, set_WHTRate] = useState();
  const [BankComm, set_BankComm] = useState(0);
  const [InvoiceDate, set_InvoiceDate] = useState(null);
  const [BuyerOrderNo, set_BuyerOrderNo] = useState(null);
  const [OrderDate, set_OrderDate] = useState(null);
  const [OtherRef, set_OtherRef] = useState(null);
  const [payterms, set_payterms] = useState(null);
  const [DueDate, set_DueDate] = useState();
  const [TaxAmount, set_TaxAmount] = useState(0);
  let TaxAmount1 = 0;
  const [InvAmount, set_InvAmount] = useState(0);
  const [InvAmount1, set_InvAmount1] = useState(0);
  const [bankRequired, set_bankRequired] = useState(false);
  const [TACOST, set_TACOST] = useState(0);
  const [DACOST, set_DACOST] = useState(0);
  const [FlightCost, set_FlightCost] = useState(0);
  const [HotelCost, set_HotelCost] = useState(0);
  const [MiscCost, set_MiscCost] = useState(0);
  const [VisaCost, set_VisaCost] = useState(0);
  const [TACOST_Desc, set_TACOST_Desc] = useState("TA Cost");
  const [DACOST_Desc, set_DACOST_Desc] = useState("DA Cost");
  const [FlightCost_Desc, set_FlightCost_Desc] = useState("Flight Cost");
  const [HotelCost_Desc, set_HotelCost_Desc] = useState("Hotel Cost");
  const [MiscCost_Desc, set_MiscCost_Desc] = useState("Misc Cost");
  const [VisaCost_Desc, set_VisaCost_Desc] = useState("Visa Cost");
  const [Loading, set_Loading] = useState(true);
  const [serviceData, set_serviceData] = useState([]);
  const [GST, set_GST] = useState("");
  const [GSTVal, set_GSTVal] = useState("");
  const [GSTVal1, set_GSTVal1] = useState("");
  const [InvoiceName, set_InvoiceName] = useState("TAX INVOICE")

  const [PAN, set_PAN] = useState("");
  const [TRN, set_TRN] = useState("");
  const [FooterRate, set_FooterRate] = useState("");
  const [TaxAmountFooter, set_TaxAmountFooter] = useState(0);
  const [NoClientCountryRequired, set_NoClientCountryRequired] = useState(
    false
  );
  const [IsLHInvoice, set_IsLHInvoice] = useState(0);
  const [VATNo, set_VATNo] = useState("");
  const [CompanyNumber, set_CompanyNumber] = useState("");
  const [GSTNo, set_GSTNo] = useState("");
  const [BusinessNumber, set_BusinessNumber] = useState("");
  const [ABN, set_ABN] = useState("");
  const [EmployerIdentificationNumber, set_EmployerIdentificationNumber] = useState("")
  const [BillingLocation, set_BillingLocation] = useState("")
  const [invoicestatus, set_invoicestatus] = useState("")
  const [AddColumnForSecondCurrency, set_AddColumnForSecondCurrency] = useState("")
  const [Currency1, set_Currency1] = useState("")
  const [IsRayontara, set_IsRayontara] = useState(0);

  const [DeliveryType, set_DeliveryType] = useState("");
  const [CityId, set_cityId] = useState(0);

  useEffect(() => {
    set_Loading(true);
    callForInvoiceShow();

    set_Loading(false);
  }, []);

  useEffect(() => {
    callForLoader();
  }, [Loading])

  const axios = require("axios");
  let rowno = 0;
  var commaNumber = require("comma-number");


  const callForLoader = () => {
    return (
      <Segment style={{ height: "100vh" }}>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  };


  const callForInvoiceShow = () => {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=20&invid=" + invid)
      .then(function (response) {
        Set_invoiceData(response.data);
        console.log("Hook line");
        console.log(response.data);
        response.data.map((i, index) => {
          setHooks(i, index);
        });
      })
      .catch(function (error) {
        alert(error);
      });

    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=12&invid=" + invid)
      .then(function (response) {
        console.log(response.data);
        Set_BankData(response.data);
        console.log('@' + response.data)

      })
      .catch(function (error) {
        alert(error);
      });
  };



  const callForBankComm = () => {

    return (
      <>
        {BankComm > 0 ? (
          <tr>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}>
              <span>Bank Commission</span>
            </td>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}>
              <span>{commaNumber(BankComm)} </span>
            </td>
            {AddColumnForSecondCurrency != 0 ? <td style={{ border: "1px solid #b0b0b0" }}>
              <span>{commaNumber(BankComm)} </span>
            </td> : null}
            {AddColumnForSecondCurrency != 0 ? <td style={{ border: "none", border: "1px solid #b0b0b0" }}></td> : null}

          </tr>
        ) : null}
      </>
    );
  };

  const callForTax = () => {
    return (
      <>
        {
          ICGST != null && ICGST > 0 ? (
            <tr>
              <td style={{ border: "1px solid #b0b0b0" }}></td>
              {invoiceNo.includes("KSPLSGP") ? "GST 9%" : invoiceNo.includes("KSCAD") ? (<td style={{ border: "1px solid #b0b0b0" }}> GST 5%   </td>) : <td style={{ border: "1px solid #b0b0b0" }}>  IGST 18%  </td>}
              {/* {invoiceNo.includes("KSPL") ? (<td> ICGST 18%" :  </td>) : null}   */}
              <td style={{ border: "1px solid #b0b0b0" }}></td> <td style={{ border: "1px solid #b0b0b0" }}></td>  <td style={{ border: "1px solid #b0b0b0" }}></td>
              <td style={{ border: "1px solid #b0b0b0" }}>             <span>{commaNumber(ICGST)} </span>              </td>
              {AddColumnForSecondCurrency != 0 ? <td style={{ border: "1px solid #b0b0b0" }}>             <span>{commaNumber(ICGST1)} </span>              </td> : null}
            </tr>
          ) : null
        }
        {
          SCGST != null && SCGST > 0 ? (
            <>
              <tr>
                <td style={{ border: "1px solid #b0b0b0" }}></td>              <td style={{ border: "1px solid #b0b0b0" }}> SGST  9% </td>              <td style={{ border: "1px solid #b0b0b0" }}></td>              <td style={{ border: "1px solid #b0b0b0" }}></td>              <td></td>
                <td style={{ border: "1px solid #b0b0b0" }}>              <span>{commaNumber(SCGST)} </span>              </td>
                {AddColumnForSecondCurrency != 0 ? <td>             <span>{commaNumber(SCGST1)} </span>              </td> : null}

              </tr>
              <tr>
                <td style={{ border: "1px solid #b0b0b0" }}></td>              <td style={{ border: "1px solid #b0b0b0" }}> CGST 9% </td>
                <td style={{ border: "1px solid #b0b0b0" }}></td>              <td style={{ border: "1px solid #b0b0b0" }}></td>              <td style={{ border: "1px solid #b0b0b0" }}></td>
                <td style={{ border: "1px solid #b0b0b0" }}>              <span>{commaNumber(SCGST)} </span>              </td>
                {AddColumnForSecondCurrency != 0 ? <td style={{ border: "1px solid #b0b0b0" }}>             <span>{commaNumber(SCGST1)} </span>              </td> : null}
              </tr>
            </>
          ) : null
        }
        {
          VAT != null && VAT > 0 ? (
            <tr>
              <td style={{ border: "1px solid #b0b0b0" }}></td>
              {
                invoiceNo.includes("KSUK") ? (<td style={{ border: "1px solid #b0b0b0" }}> VAT 20%  </td>) :
                  invoiceNo.includes("KS-SA") ? (<td style={{ border: "1px solid #b0b0b0" }}> VAT {FooterRate}% </td>) :
                    invoiceNo.includes("KSNL") ? (<td style={{ border: "1px solid #b0b0b0" }}> VAT {FooterRate}%  </td>) :
                      invoiceNo.includes("KSSaudi") ? (<td style={{ border: "1px solid #b0b0b0" }}> VAT {FooterRate}%  </td>) :
                        invoiceNo.includes("KSGMBH") ? (<td style={{ border: "1px solid #b0b0b0" }}> VAT {FooterRate}%  </td>) :
                          (invoiceNo.includes("KSL-PI") ? <td style={{ border: "1px solid #b0b0b0" }}> VAT {FooterRate}%</td> :
                            <td style={{ border: "1px solid #b0b0b0" }}> VAT 5%</td>)
              }

              <td style={{ border: "1px solid #b0b0b0" }}></td>              <td style={{ border: "1px solid #b0b0b0" }}></td>              <td style={{ border: "1px solid #b0b0b0" }}></td>
              <td style={{ border: "1px solid #b0b0b0" }}>              <span>{commaNumber(VAT)} </span>              </td>
              {AddColumnForSecondCurrency != 0 ? <td>             <span>{commaNumber(VAT1)} </span>              </td> : null}

            </tr>
          ) : null
        }
        {
          WHT != null && WHT > 0 ? (
            <tr>
              <td style={{ border: "1px solid #b0b0b0" }}></td>              <td style={{ border: "1px solid #b0b0b0" }}> WHT  {WHTRate} % </td>
              <td style={{ border: "1px solid #b0b0b0" }}></td>              <td style={{ border: "1px solid #b0b0b0" }}></td>
              <td style={{ border: "1px solid #b0b0b0" }}></td>
              <td style={{ border: "1px solid #b0b0b0" }}>               <span>{commaNumber(WHT)} </span>              </td>
              {AddColumnForSecondCurrency != 0 ? <td>             <span>{commaNumber(WHT1)} </span>              </td> : null}

            </tr>
          ) : null
        }
        {GSTVal > 0 ? (
          <tr>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}>{GSTVal != 0 ? <span> {BillingLocation == 35 && (CityId == 786) ? "HST 13%" :
              BillingLocation == 35 && (CityId == 811 || CityId == 812 || CityId == 813 || CityId == 814 || CityId == 815 || CityId == 817 || CityId == 818) ? "GST 5%" :
                BillingLocation == 35 && (CityId == 816) ? "(GST & QST) 14.975%" :
                  BillingLocation == 507 ? "GST 9%" :
                    BillingLocation == 508 ? "GST 15%" :
                      BillingLocation == 35 && (CityId == 819 || CityId == 820 || CityId == 821 || CityId == 822) ? "HST 15%" : "GST 10%"}  </span> : null}</td>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}>              <span>{commaNumber(GSTVal)} </span>              </td>
            {AddColumnForSecondCurrency != 0 ? <td>             <span>{commaNumber(GSTVal1)} </span>              </td> : null}

          </tr>
        ) : null}

      </>
    );
  };

  const ExtraServiceSection = () => {
    if (invoiceNo.includes("GST")) {
      return (
        <>
          <tr>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}>
              {TACOST > 0 ? (<span dangerouslySetInnerHTML={{ __html: TACOST_Desc + "<br>" }}></span>) : ("")}
              {DACOST > 0 ? (<span dangerouslySetInnerHTML={{ __html: DACOST_Desc + "<br>" }}></span>) : ("")}
              {FlightCost > 0 ? (<span dangerouslySetInnerHTML={{ __html: FlightCost_Desc + "<br>" }}></span>) : ("")}
              {HotelCost > 0 ? (<span dangerouslySetInnerHTML={{ __html: HotelCost_Desc + "<br>" }}></span>) : ("")}
              {MiscCost > 0 ? (<span dangerouslySetInnerHTML={{ __html: MiscCost_Desc + "<br>" }}></span>) : ("")}
              {VisaCost > 0 ? (<span dangerouslySetInnerHTML={{ __html: VisaCost_Desc + "<br>" }}></span>) : ("")}
            </td>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}></td>
            <td style={{ border: "1px solid #b0b0b0" }}>
              {TACOST > 0 ? (<span dangerouslySetInnerHTML={{ __html: commaNumber(TACOST) + "<br>" }}></span>) : ("")}
              {DACOST > 0 ? (<span dangerouslySetInnerHTML={{ __html: commaNumber(DACOST) + "<br>" }}></span>) : ("")}
              {FlightCost > 0 ? (<span dangerouslySetInnerHTML={{ __html: commaNumber(FlightCost) + "<br>" }}></span>) : ("")}
              {HotelCost > 0 ? (<span dangerouslySetInnerHTML={{ __html: commaNumber(HotelCost) + "<br>" }}></span>) : ("")}
              {MiscCost > 0 ? (<span dangerouslySetInnerHTML={{ __html: commaNumber(MiscCost) + "<br>" }}></span>) : ("")}
              {VisaCost > 0 ? (<span dangerouslySetInnerHTML={{ __html: commaNumber(VisaCost) + "<br>" }}></span>) : ("")}
            </td>
          </tr>
        </>
      )
    }
  }



  const setHooks = (i, index) => {

    if (i.RT_Line != "") {
      document.getElementById('RT_Line1').innerHTML = i.RT_Line;
    }
    //alert(i.RT_Line);
    //set_RT_Line(i.RT_Line);
    if (i.LUTNo != "") {
      set_LUTNo(i.LUTNo);
    }
    set_DeliveryType(i.DeliveryType);
    set_Currency1(i.Currency1);
    set_invoicestatus(i.InvoiceStatus);
    set_BillingLocation(i.BillingLocation);
    set_BusinessNumber(i.BusinessNumber);
    set_BankComm(i.BankComm);
    set_InvAmount(i.InvAmount);
    set_InvAmount1(i.InvAmount1);
    set_KoeAddress(i.KoeAddress);
    set_KoeName(i.KoenigName);
    set_Currency(i.Currency);
    Set_invoiceNo(i.InvoiceNo);
    set_ClientCountry(i.ClientCountry);
    set_BuyerName(i.BuyerName);
    set_BuyerAddress(i.BuyerAddress);
    set_isSez(i.isSez);


    set_ICGST(i.InvICGSTAmt);
    set_GCGST(i.InvCGSTAmt);
    set_SCGST(i.InvSGSTAmt);
    set_WHT(i.whtAmt);
    set_VAT(i.InvVatAmnt);

    set_ICGST1(i.InvICGSTAmt1);
    set_GCGST1(i.InvCGSTAmt1);
    set_SCGST1(i.InvSGSTAmt1);
    set_WHT1(i.whtAmt1);
    set_VAT1(i.InvVatAmnt1);

    set_WHTRate(i.whtRate);
    set_InvoiceDate(i.InvoiceDate);
    set_BuyerOrderNo(i.BuyerOrderNo);
    set_OrderDate(i.OrderDate);
    set_OtherRef(i.OtherRef);
    set_DueDate(i.DueDate);
    TaxAmount1 = TaxAmount1 + i.InvAmt;
    set_TaxAmount(TaxAmount1);
    set_IsRayontara(i.IsRayontara);

    set_bankRequired(i.NoBankRequired);
    set_GST(i.GST);
    set_PAN(i.PAN);
    set_TRN(i.TRN);
    set_payterms(i.PayTerms);
    set_TACOST(i.TABillAmt);
    set_DACOST(i.DABillAmt);
    set_FlightCost(i.tripamt);
    set_MiscCost(i.MiscBillAmt);
    set_VisaCost(i.VisaBillAmt);
    set_HotelCost(i.HotelCost);
    set_GSTVal(i.GST_Val);
    set_GSTVal1(i.GST_Val1);
    set_cityId(i.CityId);
    //alert(i.CityId);

    set_FooterRate(i.FooterRate);


    set_TaxAmountFooter(i.TaxAmount);
    set_VATNo(i.VATNo);
    set_CompanyNumber(i.CompanyNumber);
    set_GSTNo(i.GSTNo);
    set_ABN(i.ABN);
    set_EmployerIdentificationNumber(i.EmployerIdentificationNumber);
    set_AddColumnForSecondCurrency(i.AddColumnForSecondCurrency);

    if (i.InvoiceNo != "") {
      if (i.InvoiceNo.includes("RSEXP") || i.InvoiceNo.includes("RTEXP")) {
        set_InvoiceName("EXPORT INVOICE")
      }
    }
    if (i.IsLHInvoice > 0) {
      set_IsLHInvoice(i.IsLHInvoice);
    }
    if (i.NoClientCountryRequired == true) {
      set_NoClientCountryRequired(i.NoClientCountryRequired);
    }
    rowno++;
    set_serviceData((prevArray) => [
      ...prevArray,
      {
        invid: invid,
        Sno: rowno,
        Description: i.Description,
        StartDate: i.StartDate,
        EndDate: i.EndDate,
        NoOfParticipate: i.NoOfParticipate,
        NoOfWorkingDays: i.NoOfWorkingDays,
        InvAmt: i.InvAmt,
        InvAmt1: i.InvAmt1,
      },
    ]);
  };

  async function ActiveInvoice() {
    let res = await axios
      .get("/Invoice/proc_NewInvoiceCreate?type=21&invid=" + invid)
      .then(function (response) {
        alert("Invoice is active now")
      })
      .catch(function (error) {
        alert("Invoice not save");
      });
  }


  const myStyle = { margin: "30px 50px", padding: "30px" }
  const myStyle1 = { margin: "1% 7% 5% 7%", padding: "30px" }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



  return (
    <>
      {Loading ? (
        callForLoader()
      ) : (

        <div style={{ overflow: "scroll", height: "100vh" }}>
          <center>
            <button
              type="button"
              className="bg-gray-500 border border-gray-500 p-2 mb-4"
              onClick={handlePrint}
            >
              Print Invoice
            </button>


          </center>
          <div className="divTP">
            <div style={{ height: "100%" }} ref={componentRef}>
              {IsLHInvoice > 0 ? (<img src={LetterHead} className="divbgIMG"></img>) : null}
              <center>
                <table className="divTPtable"
                  width="1000"
                  border="0"
                  cellSpacing="0"
                  cellPadding="0"
                  style={IsLHInvoice > 0 ? (myStyle1) : (myStyle)}
                >
                  <tbody>
                    <tr>
                      <td colSpan="2"></td>
                    </tr>
                    <tr>
                      <td
                        width="60%"
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                      >

                        {invoicestatus.includes("Cancel") ? (
                          invoiceNo.includes("KSL-PI") ? (
                            <span
                              style={{
                                float: "right",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              CANCEL-PROFORMA INVOICE
                            </span>
                          ) : (
                            <span
                              style={{
                                float: "right",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              CANCEL-TAX INVOICE
                            </span>
                          )
                        ) : (
                          invoiceNo.includes("KSL-PI") ? (
                            <span
                              style={{
                                float: "right",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              PROFORMA INVOICE
                            </span>
                          ) : (
                            <span
                              style={{
                                float: "right",
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              {InvoiceName}
                            </span>
                          )
                        )}


                      </td>

                      {IsRayontara == 1 ?
                        <td width="40%" style={{ padding: "2%", float: "right", fontSize: "18px" }}>
                          <strong> Rayontara Solutions </strong>
                        </td>
                        :
                        <td width="40%" style={{ padding: "2%" }}>
                          <CallForLogo invoiceNumber={invoiceNo} />
                        </td>
                      }
                    </tr>
                    <tr>
                      <td colSpan="2">
                        {/* {isSez == 1 && invoiceNo.includes("RSEXP") ?  */}
                        <span> <center id='RT_Line1'>

                          {/* {
                          RT_Line
                          } */}
                        </center> </span>
                        {/* : ""
                    }   */}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <tr>
                          <td>

                            <tr>
                              <td width="500" valign="top" >
                                <KoeAddressDetails
                                  KoeName={KoeName}
                                  KoeAddress={KoeAddress}
                                  invoiceNumber={invoiceNo}
                                  GST={GST}
                                  PAN={PAN}
                                  TRN={TRN}
                                  BusinessNumber={BusinessNumber}
                                  invid={invid}
                                  GSTNo={GSTNo}
                                  CompanyNumber={CompanyNumber}
                                  VATNo={VATNo}
                                  ABN={ABN}
                                  EmployerIdentificationNumber={EmployerIdentificationNumber}
                                  isSez={isSez}
                                  BillingLocation={BillingLocation}
                                  LUTNo={LUTNo}

                                />
                                <BuyerAddressDetails
                                  ClientCountry={ClientCountry}
                                  BuyerName={BuyerName}
                                  BuyerAddress={BuyerAddress}
                                  NoClientCountryRequired={
                                    NoClientCountryRequired
                                  }
                                />
                              </td>
                              <td
                                width="500"
                                valign="top"
                                style={{
                                  height: "100%",
                                  borderRight: "1px solid #ccc"
                                }}
                              >
                                <BillDated
                                  invid={queryURL.query.invid}
                                  IsSEZ={isSez}
                                  CCEName={OtherRef}
                                  invoiceNumber={invoiceNo}
                                  DueDate={DueDate}
                                  InvoiceDate={InvoiceDate}
                                  OrderDate={OrderDate}
                                  PayTerms={payterms}
                                  BuyerOrderNo={BuyerOrderNo}
                                  DeliveryType={DeliveryType}
                                //  PayTermsName={set_payterms}
                                />
                              </td>
                            </tr>

                          </td>


                        </tr>



                        <tr style={{ border: "1px solid #b0b0b0" }}>
                          <td>

                            <tr className="borderB">
                              <td
                                width="100px"
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  padding: "10px",
                                  border: "1px solid #b0b0b0"
                                }}
                                className="borderB borderR"
                              >
                                S.No.
                              </td>
                              <td
                                width="500px"
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  padding: "10px",
                                  border: "1px solid #b0b0b0"
                                }}
                                className="borderB borderR"
                              >
                                Service Description
                              </td>
                              <td
                                width="100px"
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  padding: "10px",
                                  border: "1px solid #b0b0b0"
                                }}
                                className="borderB borderR"
                              >
                                Start Date
                              </td>
                              <td
                                width="100px"
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  padding: "10px",
                                  border: "1px solid #b0b0b0"
                                }}
                                className="borderB borderR"
                              >
                                End Date
                              </td>
                              <td
                                width="100px"
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  padding: "10px",
                                  border: "1px solid #b0b0b0"
                                }}
                                className="borderB borderR"
                              >
                                Working Days/Participants
                              </td>
                              <td
                                width="100px"
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  padding: "10px",
                                  border: "1px solid #b0b0b0"
                                }}
                                className="borderB "
                              >
                                Amount (
                                <span
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "10px",

                                  }}
                                >
                                  {Currency}
                                </span>
                                )
                              </td>
                              {AddColumnForSecondCurrency != 0 ? <td
                                width="100px"
                                style={{
                                  color: "#000",
                                  fontWeight: "bold",
                                  padding: "10px",
                                  border: "1px solid #b0b0b0"
                                }}
                                className="borderB "
                              >
                                Amount (
                                <span
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "10px",
                                    border: "1px solid #b0b0b0"
                                  }}
                                >
                                  {Currency1}
                                </span>
                                )
                              </td> : null}
                            </tr>
                            {/***************************************** service description row start**************************************************/}

                            {serviceData.map((data, index) => (
                              <tr key={index}>
                                <td className="borderR" style={{ border: "1px solid #b0b0b0" }}>{data.Sno}</td>
                                <td align="left" className="borderR" style={{ border: "1px solid #b0b0b0" }}>
                                  <span style={{ Display: "inline-block" }}>
                                    {data.Description}
                                    <br />
                                  </span>
                                </td>
                                <td className="borderR" style={{ border: "1px solid #b0b0b0" }}>
                                  <b>
                                    <span style={{ Display: "inline-block" }}>

                                      {data.StartDate != null
                                        ? data.StartDate
                                        : null}
                                    </span>
                                  </b>
                                </td>
                                <td className="borderR" style={{ border: "1px solid #b0b0b0" }}>
                                  <b>
                                    <span style={{ Display: "inline-block" }}>
                                      {data.EndDate != null
                                        ? data.EndDate
                                        : null}
                                    </span>
                                  </b>
                                </td>

                                <td className="borderR" style={{ border: "1px solid #b0b0b0" }}>
                                  <b>
                                    {data.NoOfWorkingDays != null &&
                                      data.NoOfWorkingDays > 0 ? (
                                      <span
                                        style={{ display: "inline-block" }}
                                      >

                                        Days : {data.NoOfWorkingDays}
                                      </span>
                                    ) : null}
                                  </b>
                                  <br />
                                  <b>
                                    {data.NoOfParticipate != null &&
                                      data.NoOfParticipate > 0 ? (
                                      <span
                                        style={{ display: "inline-block" }}
                                      >

                                        Participants  : {data.NoOfParticipate}
                                      </span>
                                    ) : null}
                                  </b>
                                </td>
                                <td className="borderR" style={{ border: "1px solid #b0b0b0" }}>
                                  <span> {commaNumber(data.InvAmt)}</span>
                                </td>

                                {AddColumnForSecondCurrency != 0 ? (<td className="borderR" style={{ border: "1px solid #b0b0b0" }}>
                                  <span> {commaNumber(data.InvAmt1)}</span>
                                </td>
                                ) : null}
                              </tr>
                            ))}
                            {/***************************************** service description row end**************************************************/}

                            {ExtraServiceSection()}
                            {/*************************bank commission row start****************************/}
                            {callForBankComm()}
                            {/*************************bank commission row end******************************/}
                            {/***************************************** tax row start**************************************************/}
                            {isSez == 1 ? null : callForTax()}
                            {/***************************************** tax row end**************************************************/}

                            <tr style={{ border: "1px solid #b0b0b0" }} >
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              {AddColumnForSecondCurrency != 0 ? <td style={{ border: "none" }}></td> : null}
                            </tr>
                            {/***************************************** bank details row start**************************************************/}
                            <CallForBank
                              BankData={BankData}
                              bankRequired={bankRequired}
                              AddColumnForSecondCurrency={AddColumnForSecondCurrency}
                            />
                            {/***************************************** bank details row end**************************************************/}

                            <tr style={{ height: "50px" }}>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              <td style={{ border: "1px solid #b0b0b0" }}></td>
                              {AddColumnForSecondCurrency != 0 ? <td style={{ border: "1px solid #b0b0b0" }}></td> : null}

                            </tr>

                            <tr>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none", float: "right" }}>
                                <strong>Total</strong>
                              </td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}>
                                <strong>
                                  <span> {Currency} </span>
                                  <span> {commaNumber(InvAmount)}</span>
                                </strong>
                                <br />
                                <div
                                  style={{
                                    float: "right",
                                    marginRight: "5px",
                                  }}
                                >
                                  E. &amp; O.E
                                </div>
                              </td>
                              {AddColumnForSecondCurrency != 0 ? (
                                <td style={{ border: "none" }}>
                                  <strong>
                                    <span> {Currency1} </span>
                                    <span> {commaNumber(InvAmount1)}</span>
                                  </strong>
                                  <br />
                                  <div
                                    style={{
                                      float: "right",
                                      marginRight: "5px",
                                    }}
                                  >
                                    E. &amp; O.E
                                  </div>
                                </td>) : null}
                            </tr>

                            <FirstFooter
                              invoiceNumber={invoiceNo}
                              TaxAmount={TaxAmount}
                              WHTRATE={WHTRate}
                              ICGST={ICGST}
                              VAT={VAT}
                              WHT={WHT}
                              SCGST={SCGST}
                              invid={invid}
                              FooterRate={FooterRate}
                              TaxAmountFooter={TaxAmountFooter}
                              FinalAmount={InvAmount}
                              AddColumnForSecondCurrency={AddColumnForSecondCurrency}
                            />

                            <br />
                          </td>
                        </tr>

                      </td>
                    </tr>
                    <SecondFooter invoiceNumber={invoiceNo} KoeName={KoeName} />
                    <tr style={{ borderBottom: "none", borderTop: "1px solid #b0b0b0", borderRight: "none", borderLeft: "none" }}>
                      <td colSpan="2" style={{ paddingTop: "12px", borderRight: "1px solid #b0b0b0" }}> <center> This is Computer Generated No Signature is Required. </center>  </td>
                    </tr>
                  </tbody>
                </table>


              </center>
            </div>
          </div>
        </div>

      )}
    </>
  )
});

export default InvoicePage;
